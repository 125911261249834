import React from 'react'
import Sidebar from "../../Sidebar/Sidebar";
import UserNavbar from "../../Navbars/UserNavbar";
import { useNavigate, Link } from 'react-router-dom';
import { SvgElement, icontypesEnum } from "../../assets/svgElement";

function AfripayShop() {
    const navigate = useNavigate();
    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64">
                <UserNavbar />
                <div className='lg:mt-3 lg:px-10 pb-6 px-6 bg-[#F8F8F8] min-h-screen'>
                    <div className='pt-8'>
                        <div className='bg-white rounded-[10px] border border-[#E7F2F3] pl-[30px] px-5 pb-3 pt-[28px]'>
                            <div className=' grid lg:grid-cols-4 grid-cols-1 gap-6'>
                                <div>
                                    <div className='flex space-x-2'>
                                        <h3 className='text-[11px] mt-10 font-bold'>Welcome to the</h3>
                                        <div className=''>
                                            <img src="/images/Dashbord/Aircraft.png" alt="logo" />
                                        </div>
                                    </div>
                                    <h1 className='text-[32px] text-[#14B266] font-extrabold'>AfriPay Shop</h1>
                                    <p className='text-[12px] text-[#6D8494]'>Your one stop shop for multiple payments powered by our wonderful partners.</p>
                                </div>
                                <div className='lg:block hidden'>
                                    <div className=''>
                                        <img src="/images/Dashbord/rafiki.png" alt="logo" />
                                    </div>
                                </div>
                                <div>

                                    <div className=''>
                                        <img src="/images/Dashbord/amico.png" alt="logo" />
                                    </div>
                                </div>
                                <div className='lg:block hidden'>

                                    <div className=''>
                                        <img src="/images/Dashbord/Character.png" alt="logo" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='pt-8'>
                        <div className='grid grid-cols-1 lg:grid-cols-3 gap-5'>
                            <div className='bg-white py-[15px] px-[20px] rounded-[10px]'>
                                {/* <!-- Icon --> */}
                                <SvgElement type={icontypesEnum.LOVE} />
                                <h3 className='mt-2 mb-2 text-[18px] font-semibold'>Health Insurance</h3>                        
                                <p className='text-[#6D8494] text-[12px]'>Discover peace of mind with our comprehensive health insurance coverage. Accessible, affordable, and tailored to your needs, ensuring you're protected when it matters most.</p>
                            <div className='pt-3'>
                                <button 
                                onClick={() => navigate("/health-insurance")}
                                className='bg-[#E7F2F3] text-[#14B266] border border-[#14B266] inline-flex rounded-[16px] text-[12px] py-[3px] px-[29px]'>Pay 
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" className='ml-2' viewBox="0 0 24 24"><path fill="#14B266" d="M13.22 19.03a.75.75 0 0 1 0-1.06L18.19 13H3.75a.75.75 0 0 1 0-1.5h14.44l-4.97-4.97a.749.749 0 0 1 .326-1.275a.749.749 0 0 1 .734.215l6.25 6.25a.75.75 0 0 1 0 1.06l-6.25 6.25a.75.75 0 0 1-1.06 0"/></svg>
                                </button>    
                            </div>
                            </div>
                            <div className='bg-white py-[15px] px-[20px] rounded-[10px] border border-[#14B266]'>
                                {/* <!-- Icon --> */}
                                <SvgElement type={icontypesEnum.FLIGHT} />
                                <h3 className='mt-2 mb-2 text-[18px] font-semibold'>Book Flight</h3>                        
                                <p className='text-[#6D8494] text-[12px]'>Fly with ease using our streamlined flight booking page. From searching to booking, we simplify the process, so you can focus on your journey ahead.</p>
                            <div className='pt-3'>
                                <button 
                                type='button'
                                onClick={() => navigate("/travel-with-afripay")}
                                className='bg-[#E7F2F3] text-[#14B266] border border-[#14B266] inline-flex rounded-[16px] text-[12px] py-[3px] px-[29px]'>Pay 
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" className='ml-2' viewBox="0 0 24 24"><path fill="#14B266" d="M13.22 19.03a.75.75 0 0 1 0-1.06L18.19 13H3.75a.75.75 0 0 1 0-1.5h14.44l-4.97-4.97a.749.749 0 0 1 .326-1.275a.749.749 0 0 1 .734.215l6.25 6.25a.75.75 0 0 1 0 1.06l-6.25 6.25a.75.75 0 0 1-1.06 0"/></svg>
                                </button>    
                            </div>
                            </div>
                            <div className='bg-white py-[15px] px-[20px] rounded-[10px]'>
                                {/* <!-- Icon --> */}
                                <SvgElement type={icontypesEnum.CARD} />
                                <h3 className='mt-2 mb-2 text-[18px] font-semibold'>Order MasterCard</h3>                        
                                <p className='text-[#6D8494] text-[12px]'>Get your Mastercard hassle-free with our convenient ordering platform. Enjoy global acceptance and secure transactions at your fingertips.</p>
                            <div className='pt-3'>
                                <button 
                                onClick={() => navigate("/savings")}
                                className='bg-[#E7F2F3] text-[#14B266] border border-[#14B266] inline-flex rounded-[16px] text-[12px] py-[3px] px-[29px]'>Pay 
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" className='ml-2' viewBox="0 0 24 24"><path fill="#14B266" d="M13.22 19.03a.75.75 0 0 1 0-1.06L18.19 13H3.75a.75.75 0 0 1 0-1.5h14.44l-4.97-4.97a.749.749 0 0 1 .326-1.275a.749.749 0 0 1 .734.215l6.25 6.25a.75.75 0 0 1 0 1.06l-6.25 6.25a.75.75 0 0 1-1.06 0"/></svg>
                                </button>    
                            </div>
                            </div>
                            <div className='bg-white py-[15px] px-[20px] rounded-[10px]'>
                                {/* <!-- Icon --> */}
                                <SvgElement type={icontypesEnum.BUS} />
                                <h3 className='mt-2 mb-2 text-[18px] font-semibold'>Bus tickets/Train tickets</h3>                        
                                <p className='text-[#6D8494] text-[12px]'>Skip the lines and book your bus or train tickets seamlessly with our user-friendly platform. Traveling has never been this effortless.</p>
                            <div className='pt-3'>
                                <button 
                                onClick={() => navigate("/savings")}
                                className='bg-[#E7F2F3] text-[#14B266] border border-[#14B266] inline-flex rounded-[16px] text-[12px] py-[3px] px-[29px]'>Pay 
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" className='ml-2' viewBox="0 0 24 24"><path fill="#14B266" d="M13.22 19.03a.75.75 0 0 1 0-1.06L18.19 13H3.75a.75.75 0 0 1 0-1.5h14.44l-4.97-4.97a.749.749 0 0 1 .326-1.275a.749.749 0 0 1 .734.215l6.25 6.25a.75.75 0 0 1 0 1.06l-6.25 6.25a.75.75 0 0 1-1.06 0"/></svg>
                                </button>    
                            </div>
                            </div>
                            <div className='bg-white py-[15px] px-[20px] rounded-[10px]'>
                                {/* <!-- Icon --> */}
                                <SvgElement type={icontypesEnum.HOME} />
                                <h3 className='mt-2 mb-2 text-[18px] font-semibold'>Pay for accommodation</h3>                        
                                <p className='text-[#6D8494] text-[12px]'>Secure your stay hassle-free with our seamless accommodation payment platform. From hotels to vacation rentals, we make booking your lodging as easy as a click.</p>
                            <div className='pt-3'>
                                <button 
                                onClick={() => navigate("/savings")}
                                className='bg-[#E7F2F3] text-[#14B266] border border-[#14B266] inline-flex rounded-[16px] text-[12px] py-[3px] px-[29px]'>Pay 
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" className='ml-2' viewBox="0 0 24 24"><path fill="#14B266" d="M13.22 19.03a.75.75 0 0 1 0-1.06L18.19 13H3.75a.75.75 0 0 1 0-1.5h14.44l-4.97-4.97a.749.749 0 0 1 .326-1.275a.749.749 0 0 1 .734.215l6.25 6.25a.75.75 0 0 1 0 1.06l-6.25 6.25a.75.75 0 0 1-1.06 0"/></svg>
                                </button>    
                            </div>
                            </div>
                            <div className='bg-white py-[15px] px-[20px] rounded-[10px]'>
                                {/* <!-- Icon --> */}
                                <SvgElement type={icontypesEnum.PRFEE} />
                                <h3 className='mt-2 mb-2 text-[18px] font-semibold'>RP/Visa Processing fees</h3>                        
                                <p className='text-[#6D8494] text-[12px]'>Eliminate visa hassles with our efficient processing system. We handle the paperwork so you can focus on planning your trip.</p>
                            <div className='pt-3'>
                                <button 
                                onClick={() => navigate("/savings")}
                                className='bg-[#E7F2F3] text-[#14B266] border border-[#14B266] inline-flex rounded-[16px] text-[12px] py-[3px] px-[29px]'>Pay 
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" className='ml-2' viewBox="0 0 24 24"><path fill="#14B266" d="M13.22 19.03a.75.75 0 0 1 0-1.06L18.19 13H3.75a.75.75 0 0 1 0-1.5h14.44l-4.97-4.97a.749.749 0 0 1 .326-1.275a.749.749 0 0 1 .734.215l6.25 6.25a.75.75 0 0 1 0 1.06l-6.25 6.25a.75.75 0 0 1-1.06 0"/></svg>
                                </button>    
                            </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default AfripayShop