import React from 'react'
import Sidebar from "../../Sidebar/Sidebar";
import UserNavbar from "../../Navbars/UserNavbar";
import HeaderTitle from '../../ReuseableComponent/HeaderTitle';
import { NavLink, useNavigate } from "react-router-dom";
import { SvgElement, icontypesEnum } from "../../assets/svgElement";

function HealthInsurance() {
    const navigate = useNavigate();


    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64">
                <UserNavbar />
                <div className='lg:mt-3 lg:px-10 pb-6 pt-2 px-6 bg-[#F8F8F8] min-h-screen'>
                    <HeaderTitle title="Health Insurance" />
                    <div className='mt-7'>
                        <div className='grid grid-cols-1 lg:grid-cols-4 gap-5'>
                            <div className='bg-white py-[15px] px-[20px] rounded-[10px]'>
                                {/* <!-- Icon --> */}
                                <SvgElement type={icontypesEnum.SPAIN} />
                                <h3 className='mt-2 mb-2 text-[16px] font-semibold'>International Student Health Insurance Spain</h3>
                               
                                <div className='pt-3'>
                                    <NavLink to="https://forms.swisscare.com/#/isies?icd=3077">
                                    <button
                                        className='bg-[#E7F2F3] text-[#14B266] border border-[#14B266] inline-flex rounded-[16px] text-[12px] py-[3px] px-[29px]'>Buy insurance
                                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" className='ml-2' viewBox="0 0 24 24"><path fill="#14B266" d="M13.22 19.03a.75.75 0 0 1 0-1.06L18.19 13H3.75a.75.75 0 0 1 0-1.5h14.44l-4.97-4.97a.749.749 0 0 1 .326-1.275a.749.749 0 0 1 .734.215l6.25 6.25a.75.75 0 0 1 0 1.06l-6.25 6.25a.75.75 0 0 1-1.06 0" /></svg>
                                    </button>
                                    </NavLink>
                                </div>
                            </div>
                            <div className='bg-white py-[15px] px-[20px] rounded-[10px]'>
                                {/* <!-- Icon --> */}
                                <SvgElement type={icontypesEnum.EURO} />
                                <h3 className='mt-2 mb-2 text-[16px] font-semibold'>International Student Health Insurance Europe</h3>
                               
                                <div className='pt-3'>
                                    <NavLink to="https://forms.swisscare.com/#/isie?icd=3077">
                                    <button   
                                        className='bg-[#E7F2F3] text-[#14B266] border border-[#14B266] inline-flex rounded-[16px] text-[12px] py-[3px] px-[29px]'>Buy insurance
                                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" className='ml-2' viewBox="0 0 24 24"><path fill="#14B266" d="M13.22 19.03a.75.75 0 0 1 0-1.06L18.19 13H3.75a.75.75 0 0 1 0-1.5h14.44l-4.97-4.97a.749.749 0 0 1 .326-1.275a.749.749 0 0 1 .734.215l6.25 6.25a.75.75 0 0 1 0 1.06l-6.25 6.25a.75.75 0 0 1-1.06 0" /></svg>
                                    </button>
                                    </NavLink>
                                </div>
                            </div>
                            <div className='bg-white py-[15px] px-[20px] rounded-[10px]'>
                                {/* <!-- Icon --> */}
                                <SvgElement type={icontypesEnum.GERMANY} />
                                <h3 className='mt-2 mb-2 text-[16px] font-semibold'>International Student Health Insurance Germany</h3>
                               
                                <div className='pt-3'>
                                    <NavLink to="https://forms.swisscare.com/#/isig?icd=3077">
                                    <button
                                        className='bg-[#E7F2F3] text-[#14B266] border border-[#14B266] inline-flex rounded-[16px] text-[12px] py-[3px] px-[29px]'>Buy insurance
                                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" className='ml-2' viewBox="0 0 24 24"><path fill="#14B266" d="M13.22 19.03a.75.75 0 0 1 0-1.06L18.19 13H3.75a.75.75 0 0 1 0-1.5h14.44l-4.97-4.97a.749.749 0 0 1 .326-1.275a.749.749 0 0 1 .734.215l6.25 6.25a.75.75 0 0 1 0 1.06l-6.25 6.25a.75.75 0 0 1-1.06 0" /></svg>
                                    </button>
                                    </NavLink>
                                </div>
                            </div>
                            <div className='bg-white py-[15px] px-[20px] rounded-[10px]'>
                                {/* <!-- Icon --> */}
                                <SvgElement type={icontypesEnum.LATVIA} />
                                <h3 className='mt-2 mb-2 text-[16px] font-semibold'>International Student Health Insurance Latvia</h3>
                               
                                <div className='pt-3'>
                                    <NavLink to="https://forms.swisscare.com/#/isilv?icd=3077">
                                    <button
                                        className='bg-[#E7F2F3] text-[#14B266] border border-[#14B266] inline-flex rounded-[16px] text-[12px] py-[3px] px-[29px]'>Buy insurance
                                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" className='ml-2' viewBox="0 0 24 24"><path fill="#14B266" d="M13.22 19.03a.75.75 0 0 1 0-1.06L18.19 13H3.75a.75.75 0 0 1 0-1.5h14.44l-4.97-4.97a.749.749 0 0 1 .326-1.275a.749.749 0 0 1 .734.215l6.25 6.25a.75.75 0 0 1 0 1.06l-6.25 6.25a.75.75 0 0 1-1.06 0" /></svg>
                                    </button>
                                    </NavLink>
                                </div>
                            </div>
                            <div className='bg-white py-[15px] px-[20px] rounded-[10px]'>
                                {/* <!-- Icon --> */}
                                <SvgElement type={icontypesEnum.LITH} />
                                <h3 className='mt-2 mb-2 text-[16px] font-semibold'>International Student Health Insurance Lithuania</h3>
                               
                                <div className='pt-3'>
                                    <NavLink to="https://forms.swisscare.com/#/isilt?icd=3077">
                                    <button
                                        className='bg-[#E7F2F3] text-[#14B266] border border-[#14B266] inline-flex rounded-[16px] text-[12px] py-[3px] px-[29px]'>Buy insurance
                                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" className='ml-2' viewBox="0 0 24 24"><path fill="#14B266" d="M13.22 19.03a.75.75 0 0 1 0-1.06L18.19 13H3.75a.75.75 0 0 1 0-1.5h14.44l-4.97-4.97a.749.749 0 0 1 .326-1.275a.749.749 0 0 1 .734.215l6.25 6.25a.75.75 0 0 1 0 1.06l-6.25 6.25a.75.75 0 0 1-1.06 0" /></svg>
                                    </button>
                                    </NavLink>
                                </div>
                            </div>
                            <div className='bg-white py-[15px] px-[20px] rounded-[10px]'>
                                {/* <!-- Icon --> */}
                                <SvgElement type={icontypesEnum.PRFEE} />
                                <h3 className='mt-2 mb-2 text-[16px] font-semibold'>Schengen Visa Insurance</h3>
                               
                                <div className='pt-3'>
                                    <NavLink to="https://forms.swisscare.com/#/itvs?icd=3077">
                                    <button                            
                                        className='bg-[#E7F2F3] text-[#14B266] border border-[#14B266] inline-flex rounded-[16px] text-[12px] py-[3px] px-[29px]'>Buy insurance
                                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" className='ml-2' viewBox="0 0 24 24"><path fill="#14B266" d="M13.22 19.03a.75.75 0 0 1 0-1.06L18.19 13H3.75a.75.75 0 0 1 0-1.5h14.44l-4.97-4.97a.749.749 0 0 1 .326-1.275a.749.749 0 0 1 .734.215l6.25 6.25a.75.75 0 0 1 0 1.06l-6.25 6.25a.75.75 0 0 1-1.06 0" /></svg>
                                    </button>
                                    </NavLink>
                                </div>
                            </div>
                            <div className='bg-white py-[15px] px-[20px] rounded-[10px]'>
                                {/* <!-- Icon --> */}
                                <SvgElement type={icontypesEnum.LOVE} />
                                <h3 className='mt-2 mb-2 text-[16px] font-semibold'>International Essential Medical Insurance</h3>
                               
                                <div className='pt-3'>
                                    <NavLink to="https://forms.swisscare.com/#/emed?icd=3077">
                                    <button                                
                                        className='bg-[#E7F2F3] text-[#14B266] border border-[#14B266] inline-flex rounded-[16px] text-[12px] py-[3px] px-[29px]'>Buy insurance
                                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" className='ml-2' viewBox="0 0 24 24"><path fill="#14B266" d="M13.22 19.03a.75.75 0 0 1 0-1.06L18.19 13H3.75a.75.75 0 0 1 0-1.5h14.44l-4.97-4.97a.749.749 0 0 1 .326-1.275a.749.749 0 0 1 .734.215l6.25 6.25a.75.75 0 0 1 0 1.06l-6.25 6.25a.75.75 0 0 1-1.06 0" /></svg>
                                    </button>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HealthInsurance